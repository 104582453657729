<template>
  <div class="flex h-full">
    <div class="bg-primary-body w-2/6 h-full hidden lg:flex items-center justify-center">
      <img
        src="@/assets/img/aside-img-login.png"
        alt="Bienvenido a SingularRank"
      >
    </div>
    <div class="flex flex-col w-full lg:w-4/6 min-h-full bg-white">
      <div class="flex-shrink-0 flex items-center p-4 justify-between">
        <div>
          <span class="text-sm text-gray-500">Está en: </span><span class="font-bold text-sm">Política de cookies</span>
        </div>
        <router-link :to="{name: 'login'}">
          <base-button
            mode="soft"
            type="submit"
            :text="$t('app.back-login')"
          />
        </router-link>
      </div>
      <div class="flex flex-col flex-grow overflow-auto">
        <div class="w-copy mx-auto mt-14">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Uso de cookies</h2>
          </header>
          <p>Utilizamos Cookies propias y de terceros para mejorar nuestros servicios y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Si continua navegando, consideramos que acepta su uso.</p>
          <p>Puede obtener más información acerca de nuestra Política de Cookies accediendo al link del mismo nombre incluido en todas nuestras páginas de navegación.</p>
          <ul>
            <li>Podemos recabar información sobre su ordenador, incluido, en su caso, su dirección de IP, sistema operativo y tipo de navegador, para la administración del sistema. Se trata de datos estadísticos sobre cómo navegas por nuestro sitio Web.</li>
            <li>Por la misma razón, podemos obtener información sobre su uso general en nuestra tienda mediante un archivo de cookies que se almacena en el disco duro de su ordenador. Las cookies contienen información que se transfiere al disco duro de su ordenador.</li>
            <li>Las cookies nos ayudan a mejorar nuestro sitio Web y a prestar un servicio mejor y más personalizado. En concreto, nos permiten:
              <ul class="mt-2">
                <li>Hacer una estimación sobre números y patrones de uso.</li>
                <li>Almacenar información acerca de sus preferencias y personalizar nuestro sitio web de conformidad con sus intereses individuales.</li>
                <li>Acelerar sus búsquedas.</li>
                <li>Reconocerle cuando regrese de nuevo a nuestro sitio.</li>
              </ul>
            </li>
            <li>Puede negarse a aceptar cookies activando la configuración en su navegador que permite rechazar las cookies. No obstante, si selecciona esta configuración, quizá no pueda acceder a determinadas partes del Sitio Web o no pueda aprovecharse de alguno de nuestros servicios. A menos que haya ajustado la configuración de su navegador de forma que rechace cookies, nuestro sistema producirá cookies cuando se conecte a nuestro sitio.</li>
            <li>¿Cómo puedo desactivar las cookies?</li>
            <li>La mayoría de los navegadores indican cómo configurar su navegador para que no acepte cookies, para que le notifique cada vez que reciba una nueva cookie, así como para desactivarlas completamente. Para controlar qué uso quiere que se haga de su información, puede configurar el navegador de su terminal en la forma que estime más conveniente para Vd. Le indicamos los siguientes enlaces, donde podrá encontrar más información sobre los diferentes navegadores:
              <ul class="mt-2">
                <li><a href="https://www.google.es/intl/es/policies/technologies/managing/" target="_blank">Configurar cookies en Google Chrome</a></li>
                <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Configurar cookies en Microsoft Internet Explorer</a></li>
                <li><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-lossitios-we" target="_blank">Configurar cookies en Mozilla Firefox</a></li>
                <li><a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES" target="_blank">Configurar cookies en Safari (Apple)</a></li>
                <li><a href="http://www.opera.com/help/tutorials/security/privacy/" target="_blank">Configurar cookies en Opera</a></li>
              </ul>
            </li>
          </ul>
          <p>Si no desea ser rastreado por las cookies de Google Analytics, Google ha desarrollado un complemento para instalar en su navegador al que puede acceder en el <a href="http://goo.gl/up4ND" target="_blank">siguiente enlace.</a></p>
        </div>
      </div>
      <public-footer />
    </div>
  </div>
</template>

<script>
import PublicFooter from '@/components/PublicFooter.vue'
export default {
  name: 'Login',
  components: {
    PublicFooter
  },
  data: function () {
    return {}
  }

}
</script>
<style lang="scss" scoped>
.w-copy {
  width: 64ch;
}
p {
  @apply leading-relaxed;
  padding: 0px 0px 1.6em 0px;
}

ul {
  @apply list-decimal pl-6 pb-4;
  li {
    padding-bottom: 0.5em;
  }
}
</style>
