<template>
  <div class="flex h-full">
    <div class="bg-primary-body w-2/6 h-full hidden lg:flex items-center justify-center">
      <img src="@/assets/img/aside-img-login.png" alt="Bienvenido a SingularRank" >
    </div>
    <div class="flex flex-col w-full lg:w-4/6 min-h-full bg-white">
      <div class="flex-shrink-0 flex items-center justify-end p-4">
        <p class="text-sm pr-2">{{ $t('app.forgotYourPassword') }}</p>
        <router-link :to="{name: 'forgot-password'}">
          <base-button mode="soft" type="submit" :text="$t('app.recover')" />
        </router-link>
      </div>
      <div class="flex flex-grow items-center justify-center">
        <div class="w-full px-4 max-w-lg mx-auto">
          <router-link :to="{ path: '/' }">
            <base-logo filename="singular-rank-logo-horizontal.svg" alt="Bienvenido a SingularRank" custom="mx-auto pb-4" />
          </router-link>

          <div v-if="$route.params.feedback === 'sendedForgotEmail'" class="flex bg-secondary text-secondary-50 shadow-lg items-center w-full mx-auto rounded-lg text-sm p-1 mb-2">
            <span class="w-9 h-9 rounded-full flex flex-shrink-0 items-center justify-center bg-black bg-opacity-20">
              <BaseIcon name="bell" size="sm" />
            </span>
            <span class="flex-grow ml-2 font-medium">{{$t('feedback.info.sendedForgotEmail')}}</span>
          </div>

          <div v-if="$route.params.feedback === 'updatedPassword'" class="flex bg-secondary text-secondary-50 shadow-lg items-center w-full mx-auto rounded-lg text-sm p-1 mb-2">
            <span class="w-9 h-9 rounded-full flex flex-shrink-0 items-center justify-center bg-black bg-opacity-20">
              <BaseIcon name="bell" size="sm" />
            </span>
            <span class="flex-grow ml-2 font-medium">{{$t('feedback.info.updatedPassword')}}</span>
          </div>

          <form @submit.prevent="doLogin">
            <div class="w-full mb-2">
              <label class="text-xs uppercase font-semibold text-secondary-500">
                {{ $t('app.email') }}
              </label>
              <base-input prepend="user" custom="w-full" v-model="form.email" type="email" :placeholder="$t('app.email')" name="currentPage"/>
            </div>
            <div class="w-full mb-4">
              <label class="text-xs uppercase font-semibold text-secondary-500">
                {{ $t('app.password') }}
              </label>
              <base-input prepend="lock" custom="w-full" v-model="form.password" type="password" :placeholder="$t('app.password')" name="currentPage"/>
            </div>
            <base-button :loading="loading" class="w-full" type="submit" :text="$t('app.login')" />
            <p v-if="error" class="text-sm mt-2 text-center text-danger font-medium">{{ $t('feedback.error.wrongLogin') }}</p>
          </form>
        </div>
      </div>
      <public-footer />
    </div>
  </div>
</template>

<script>
import PublicFooter from '@/components/PublicFooter.vue'
export default {
  name: 'Login',
  components: {
    PublicFooter
  },
  data: function () {
    return {
      error: false,
      form: {
        email: '',
        password: ''
      },
      loading: false
    }
  },
  methods: {
    doLogin: async function () {
      if (this.form.email && this.form.password) {
        try {
          this.loading = true
          await this.$store.dispatch('auth/login', this.form)
        } catch (error) {
          this.doFeedback(this.$i18n.t('feedback.error.wrongLogin'))
        } finally {
          this.loading = false
        }
      } else {
        this.doFeedback(this.$t('feedback.error.wrongLogin'))
      }
    }
  }
}
</script>
