<template>
  <div class="flex h-full">
    <div class="bg-primary-body w-2/6 h-full hidden lg:flex items-center justify-center">
      <img
        src="@/assets/img/aside-img-login.png"
        alt="Bienvenido a SingularRank"
      >
    </div>
    <div class="flex flex-col w-full lg:w-4/6 min-h-full bg-white">
      <div class="flex-shrink-0 flex items-center p-4 justify-between">
        <div>
          <span class="text-sm text-gray-500">Está en: </span><span class="font-bold text-sm">Aviso Legal</span>
        </div>
        <router-link :to="{name: 'login'}">
          <base-button
            mode="soft"
            type="submit"
            :text="$t('app.back-login')"
          />
        </router-link>
      </div>
      <div class="flex flex-col flex-grow overflow-auto">
        <div class="w-copy mx-auto mt-14">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Registro y contacto</h2>
          </header>
          <p>El dominio www.singularprice.com es titularidad de Singularbrain Consulting, S.L., con NIF B52559952, titular de los derechos de propiedad intelectual sobre las Webs y sus contenidos, sin perjuicio de los derechos legítimos de terceros.</p>
          <p>La sociedad titular está inscrita en el Registro Mercantil de Asturias en el Tomo 4296, Folio 6, Inscripción 1, Hoja AS-52727, provista de NIF: B52559952, y tiene su domicilio a los efectos del presente Aviso Legal, en Parque científico y tecnológico de Gijón edificio Impulsa Los Prados 166, 33203 en Gijón, Asturias, Ud. puede ponerse en contacto con nosotros en el <a
              href="mailto:info@singularbrain.com"
              target="_blank"
            >siguiente enlace</a> (Abrirá el correo electrónico).</p>
        </div>
        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Términos y Condiciones</h2>
          </header>
          <p>El usuario accede voluntariamente a este sitio web. El acceso y navegación en este sitio web implica aceptar y conocer las advertencias legales, condiciones y términos de uso contenidos en ella. El mero acceso no implica el establecimiento de ningún tipo de relación comercial entre Singularbrain Consulting, S.L. y el usuario.</p>
          <p>El Aviso Legal y/o las Condiciones de los servicios prestados a través del presente sitio web, podrá sufrir modificaciones de cualquier tipo, cuando Singularbrain Consulting, S.L. lo considere oportuno o con la finalidad de adecuarse a los cambios legislativos y tecnológicos futuros. Estas modificaciones serán notificadas convenientemente siendo válidas desde su publicación en este sitio web, salvo manifestación en contrario.</p>
          <p>Singularbrain Consulting, S.L. se reserva el derecho a suspender o cancelar el acceso en el caso que se utilice para incumplir cualquier ley aplicable o las normas de uso habituales en internet, o bien si se utiliza su dominio en relación con una actividad ilegal, así como si se recibe notificación en ese sentido emitido por una autoridad competente o en los demás supuestos contemplados por la legislación vigente.</p>
          <p>Los usuarios, en la utilización del sitio web y de los servicios vinculados al mismo, deberán respetar las siguientes obligaciones:</p>
          <ul>
            <li>No introducir, almacenar o difundir mediante el sitio web o a través de cualquiera de los servicios vinculados al mismo ningún programa de ordenador, dato, virus, código, o cualquier otro instrumento o dispositivo electrónico que sea susceptible de causar daños en el sitio web, en cualquiera de los servicios vinculados al mismo o en cualesquiera equipos, sistemas o redes de la titular del dominio, de cualquier usuario, de sus proveedores o en general de cualquier tercero, o que de otra forma sea capaz de causarles cualquier tipo de alteración o impedir el normal funcionamiento del mismo.</li>
            <li>No destruir, alterar, inutilizar o dañar los datos, informaciones, programas o documentos electrónicos de la titular del dominio, de sus proveedores o de terceros.</li>
            <li>No utilizar los contenidos y en particular la información obtenida a través del sitio web para remitir publicidad, enviar mensajes con fines de venta o con cualquier otro fin comercial, ni para recoger o almacenar datos personales de terceros.</li>
            <li>Deberán abstenerse de eludir o manipular cualesquiera dispositivos técnicos establecidos, ya sea en el sitio web, en cualquiera de los servicios o en cualquiera de los materiales, elementos o información obtenidos a través del mismo, para la protección de sus derechos.</li>
            <li>Deberán abstenerse de suprimir los signos identificativos de los derechos (de propiedad intelectual, industrial o cualquier otro) de la titular del dominio o de los terceros que figuren en el sitio web y en cada uno de los diversos servicios ofrecidos a través de él.</li>
            <li>Se abstendrán de realizar cualquier actuación que pudiera resultar en una desviación de tráfico del sitio web.</li>
          </ul>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Productos y/o Servicios</h2>
          </header>
          <p>Singularbrain Consulting, S.L. ofrece determinados servicios/productos a los usuarios, sometidos a los términos y condiciones indicados en el presente sitio web, debiendo el usuario leer con atención las condiciones que se apliquen a cada servicio, que en ningún caso eximirán, salvo manifestación expresa en contrario, del cumplimiento de lo expresado en el Aviso Legal.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Contenidos</h2>
          </header>
          <p>Singularbrain Consulting, S.L. realiza los máximos esfuerzos para evitar errores en los contenidos que se publican en el sitio web. Todos los contenidos que se ofrecen a través del sitio web se encuentran actualizados, reservándose Singularbrain Consulting, S.L. la facultad de poder modificarlos en cualquier momento. Singularbrain Consulting, S.L. no se responsabiliza de las consecuencias que puedan derivarse de los errores en los contenidos proporcionados por terceros que puedan aparecer en este sitio web.</p>
          <p>Singularbrain Consulting, S.L. ofrece enlaces, directa o indirectamente, a recursos o sitios de Internet que se encuentran fuera del presente sitio web. La presencia de estos enlaces en el sitio web tienen una finalidad informativa.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Limitación de responsabilidad</h2>
          </header>
          <p>No se garantiza que los servicios del sitio Web sean ininterrumpidos, oportunos, seguros o libres de error, ni se ofrece ninguna garantía con respecto a los resultados que se puedan obtener del uso de los servicios o de la precisión o fiabilidad de cualquier información obtenida. No se controlan, ni se hacen propios, ni se garantiza la exactitud, calidad, veracidad, fiabilidad o idoneidad de las informaciones y servicios facilitados o prestados por terceros a través del sitio Web. De igual forma, no controla y no garantiza la ausencia de virus u otros elementos lesivos en los contenidos o servicios facilitados o prestados por terceros a través del sitio Web.</p>
          <p>Singularbrain Consulting, S.L. tampoco se responsabiliza del contenido de los sitios web a los que enlaza fuera de su página. Tampoco será responsable de cualquier daño o pérdida que pueda surgir por la conexión o por el uso de cualquier contenido, producto o servicio disponible en los sitios web a los que se dirijan los enlaces. Singularbrain Consulting, S.L. no puede garantizar la disponibilidad y el correcto funcionamiento de los enlaces a otros sitios web.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Propiedad intelectual e industrial</h2>
          </header>
          <p>Este sitio web y los contenidos que alberga se encuentran protegidos por la legislación vigente en materia de propiedad intelectual. Se prohíbe la modificación, copia, reproducción, descarga, transmisión, distribución o transformación de los contenidos del Portal, si no se tiene la autorización del titular de los correspondientes derechos o se encuentra legalmente permitido. El acceso al sitio web no supone en ningún caso adquisición por parte de los usuarios de derecho de propiedad alguno sobre los contenidos que figuran en el mismo.</p>
          <p>Las referencias a marcas comerciales o marcas registradas o signos distintivos, ya sean titularidad de Singularbrain Consulting, S.L. o de terceras empresas, llevan implícitas la prohibición sobre su uso sin el consentimiento de Singularbrain Consulting, S.L. o sus legítimos propietarios. En ningún momento, salvo manifestación expresa el acceso o uso del Portal y/o de sus contenidos, confiere al usuario derecho alguno sobre las marcas, logos, y/o signos distintivos en él incluidos protegidos por Ley.</p>
        </div>
      </div>
      <public-footer />
    </div>
  </div>
</template>

<script>
import PublicFooter from '@/components/PublicFooter.vue'
export default {
  name: 'LegalNotice',
  components: {
    PublicFooter
  },
  data: function () {
    return {}
  }

}
</script>
<style lang="scss" scoped>
.w-copy {
  width: 64ch;
}
p {
  @apply leading-relaxed;
  padding: 0px 0px 1.6em 0px;
}

ul {
  @apply list-disc pl-6 pb-4;
  li {
    padding-bottom: 0.5em;
  }
}
</style>
