<template>
  <div class="flex h-full">
    <div class="bg-primary-body w-2/6 h-full hidden lg:flex items-center justify-center">
      <img
        src="@/assets/img/aside-img-login.png"
        alt="Bienvenido a SingularRank"
      >
    </div>
    <div class="flex flex-col w-full lg:w-4/6 min-h-full bg-white">
      <div class="flex-shrink-0 flex items-center p-4 justify-between">
        <div>
          <span class="text-sm text-gray-500">Está en: </span><span class="font-bold text-sm">Política de privacidad</span>
        </div>
        <router-link :to="{name: 'login'}">
          <base-button
            mode="soft"
            type="submit"
            :text="$t('app.back-login')"
          />
        </router-link>
      </div>
      <div class="flex flex-col flex-grow overflow-auto">
        <div class="w-copy mx-auto mt-14">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Consentimiento</h2>
            <p>En cumplimiento de lo dispuesto en el Reglamento General de Protección de Datos vigente en la Unión Europea, el titular de este consentimiento autoriza a Singularbrain Consulting, S.L. a tratar sus datos personales, y otorga esta autorización de manera expresa, libre, inequívoca e informada.</p>
            <p>De la misma manera, el titular de este consentimiento ha sido informado de los siguientes datos:</p>
          </header>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Responsable del tratamiento</h2>
          </header>
          <p>El responsable del tratamiento es Singularbrain Consulting, S.L. con domicilio en Parque científico y tecnológico de Gijón edificio Impulsa Los Prados 166, 33203 en Gijón, Asturias. Email: info@singularbrain.com</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Finalidad</h2>
          </header>
          <p>Los datos serán utilizados para:</p>
          <ul>
            <li>Gestionar la solicitud de la información requerida.</li>
            <li>Mantenerle informado sobre la actividad y promociones de Singularbrain Consulting, S.L. (enviarle comunicaciones de nuestros productos, servicios, novedades y actividades, inclusive por medios electrónicos, si marca la casilla de aceptación).</li>
          </ul>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Plazo de conservación</h2>
          </header>
          <p>Los datos personales proporcionados se conservarán durante un plazo de cinco años a partir de la última confirmación de su interés, salvo que se solicite su supresión por el interesado.</p>
          <p>Una vez resulta su solicitud de información por medio de nuestro formulario o contestada por correo electrónico, si no ha generado un nuevo tratamiento, los datos personales proporcionados se conservaran desde ese momento y durante los plazos previstos en las leyes para el cumplimiento de obligaciones legales y para la atención y defensa de las posibles responsabilidades nacidas del tratamiento.</p>
          <p>Y en caso de haber aceptado recibir envíos comerciales, (mantenerle informado sobre la actividad) dichos datos serán conservados mientras dure la prestación del servicio solicitado, hasta que solicite la baja de los mismos. Y una vez finalice nuestra relación, durante los plazos previstos en las leyes para el cumplimiento de obligaciones legales y para la atención y defensa de las posibles responsabilidades nacidas del tratamiento.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Legitimización</h2>
          </header>
          <p>La base jurídica del tratamiento tiene su fundamento en el consentimiento que usted como usuario presta al solicitarnos información a través de nuestro formulario de contactos y al marcar la casilla de aceptación de envío de información.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Destinatarios</h2>
          </header>
          <p>No está prevista la cesión de los datos personales a terceros, con excepción de la información expresamente recogida por Ley.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Derechos</h2>
          </header>
          <p>El interesado podrá ejercitar los derechos en relación sus datos personales que le concede el RGPD. Derechos de acceso, rectificación, supresión, a la limitación del tratamiento, a la portabilidad de los datos y a la oposición. El ejercicio de dichos derechos podrá ejercitarlo dirigiéndose por escrito al responsable del tratamiento. Asimismo, tiene derecho a retirar el consentimiento prestado y a reclamar ante la Agencia española de Protección de Datos, en su condición de Autoridad de Control. Si necesita ayuda para formalizar dicha solicitud, le facilitaremos un formulario a tal efecto.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Procedencia</h2>
          </header>
          <p>Los datos recogidos son facilitados por el interesado a través de los formularios de la página web www.singularprice.com.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Información</h2>
          </header>
          <p>Todos los datos serán tratados conforme a esta política privacidad. Si no estuvieras de acuerdo con la misma, por favor, no nos envíes tus datos. Recuerda que puede ponerse en contacto con nosotros a través del correo electrónico info@singularbrain.com o visitarnos en nuestras instalaciones, para recibir información al respecto sin necesidad de remitirnos datos personales. Cuando nos envías tus datos personales a través del formulario de contacto, es necesario que previamente aceptes esta política de privacidad y para ello, deberás marcar la casilla del checkbox “He leído y acepto los términos de la Política de Privacidad”. Nuestro sistema no te permitirá enviar el formulario, sino aceptas la política previamente.</p>
          <p>Cuando rellenas nuestro formulario de contacto web, nos estás enviando tu nombre, teléfono y correo electrónico, datos personales sometidos a la legislación vigente. Por ello, nos autorizas de manera expresa a la recogida y tratamiento de los mismos, bajo las condiciones que estás leyendo. Esta información será empleada exclusivamente para gestionar las finalidades recogidas en este consentimiento.</p>
          <p>Los usuarios responderán, en cualquier caso, de la veracidad de los datos facilitados y se hacen responsables de comunicar cualquier modificación en los mismos, quedando Singularbrain Consulting, S.L. exento de cualquier tipo de responsabilidad a este respecto.</p>
          <p>Se advierte al Usuario que, salvo la existencia de una representación legalmente constituida, ningún Usuario puede utilizar la identidad de otra persona y comunicar sus datos personales, por lo que el Usuario en todo momento deberá tener en cuenta que, solo puede incluir datos personales correspondientes a su propia identidad y que sean adecuados, pertinentes, actuales, exactos y verdaderos. A tales efectos, el Usuario será el único responsable frente a cualquier daño, directo y/o indirecto que cause a terceros o a Singularbrain Consulting, S.L. por el uso de datos personales de otra persona, o sus propios datos personales cuando sean falsos, erróneos, no actuales, inadecuados o impertinentes. Igualmente el Usuario que utilice los datos personales de un tercero, responderá ante éste de la obligación de información establecida en el artículo 5.4 de la LOPD para cuando los datos de carácter personal no hayan sido recabados del propio interesado y/o de las consecuencias de no haberle informado.</p>
          <p>Queda prohibido su uso por personas menores de 18 años, en el supuesto de que el usuario sea menor de edad, éste sólo podrá aportar sus datos personales en los formularios de recogida de los mismos, con el previo consentimiento de los padres o tutores, enviando el formulario correspondiente debidamente firmado por los padres o tutores por correo certificado y con copia del DNI del padre o tutor firmante a la siguiente dirección: info@singularbrain.com.</p>
          <p>Singularbrain Consulting, S.L. no responde de aquellos datos de menores que sin poder conocer este hecho se hayan facilitado sin el consentimiento de los padres o tutores, por lo que no se hace responsable de sus acciones, siendo en todo momento el propio menor, sus padres o tutores quienes se harán cargo de las consecuencias legales y los gastos que de ello se pudieran derivar.</p>
          <p>Si alguna información provista por el Usuario resultara falsa o inexacta, Singularbrain Consulting, S.L. se reserva el derecho de cancelar la suscripción del Usuario y dar por terminados los derechos del Usuario a utilizar el Servicio.</p>
          <p>Estos datos personales serán incorporados en un registro titularidad de Singularbrain Consulting, S.L., que mantenemos en el Registro General de Protección de Datos. Los datos personales, se tratarán principalmente en soporte automatizado, sometidos a controles y medidas de seguridad adecuadas, que evitarán en la medida de lo posible, los accesos indebidos, pérdidas de información o corrupción de los datos.</p>
          <p>Disponemos de servidores con capacidad suficiente para mantener estos datos con plenas garantías de seguridad, integridad y disponibilidad. Como titular de los datos puedes ejercer tus derechos dirigiéndote a la dirección de correo electrónico info@singularbrain.com, indicando en el asunto el derecho que quieres ejercer.</p>
          <p>Cuando se envíen comunicaciones comerciales a los usuarios de nuestra web que hayan remitido el formulario de contacto, se establecerá en la comunicación, las indicaciones claras de cómo proceder a revocar este consentimiento. Todos los envíos de carácter comercial o publicitario, podrán ser cancelados por los usuarios en todo momento y siempre con un procedimiento sencillo y gratuito, mediante correo electrónico solicitando baja o mediante el uso de un enlace de cancelación. Por favor, no dudes en consultarnos este tema en cualquier momento o acceder a esta política de privacidad nuevamente, en nuestra página web.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Derechos de protección de datos</h2>
          </header>
          <p>Los Responsables del Tratamiento se comprometen a respetar la confidencialidad de sus Datos Personales y a garantizarle el ejercicio de sus derechos. En todo momento podrá ejercer sus derechos de acceso, rectificación, cancelación, oposición, supresión y limitación mediante el envío de un correo electrónico a: info@singularbrain.com, indicándonos el motivo de su solicitud. En caso necesario, podremos solicitarle copia de su DNI, pasaporte u otro documento válido que lo identifique. En el caso de que decidiese ejercer dichos derechos y que como parte de los datos personales que nos hubiera facilitado conste el correo electrónico le agradeceríamos que en la mencionada comunicación se hiciera constar específicamente esa circunstancia, indicando la dirección de correo electrónico respecto de la que desea ejercitar su derecho.</p>
        </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-2xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Información sobre cookies</h2>
          </header>
          <p>En cumplimiento con lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, esta página web le informa, en esta sección, sobre la política de recogida y tratamiento de cookies.</p>
          <p>Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.</p>
          <p>Esta página web utiliza los siguientes tipos de cookies:</p>
          <ul>
            <li>
              <h3 class="font-bold mb-2">Cookies de análisis</h3>
              <p>Son aquellas que bien tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.</p>
            </li>
            <li>
              <h3 class="font-bold mb-2">Cookies técnicas</h3>
              <p>Son aquellas que permiten al usuario la navegación a través del área restringida y la utilización de sus diferentes funciones, como por ejemplo, llevar a cambio el proceso de compra de un artículo.</p>
            </li>
            <li>
              <h3 class="font-bold mb-2">Cookies de personalización</h3>
              <p>Son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma o el tipo de navegador a través del cual se conecta al servicio.</p>
            </li>
            <li>
              <h3 class="font-bold mb-2">Cookies publicitarias</h3>
              <p>Son aquellas que, bien tratadas por esta web o por terceros, permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.</p>
            </li>
            <li>
              <h3 class="font-bold mb-2">Cookies de publicidad comportamental</h3>
              <p>Son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Este tipo de cookies almacenan información del comportamiento de los visitantes obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar avisos publicitarios en función del mismo.</p>
            </li>
          </ul>
          <p>Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
          <p>En la mayoría de los navegadores web se ofrece la posibilidad de permitir, bloquear o eliminar las cookies instaladas en su equipo.</p>
          <p>A continuación, puede acceder a la configuración de los navegadores webs más frecuentes para aceptar, instalar o desactivar las cookies:</p>
          <ul>
            <li><a href="https://www.google.es/intl/es/policies/technologies/managing/" target="_blank">Configurar cookies en Google Chrome</a></li>
            <li><a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Configurar cookies en Microsoft Internet Explorer</a></li>
            <li><a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-lossitios-we" target="_blank">Configurar cookies en Mozilla Firefox</a></li>
            <li><a href="https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES" target="_blank">Configurar cookies en Safari (Apple)</a></li>
            <li><a href="http://www.opera.com/help/tutorials/security/privacy/" target="_blank">Configurar cookies en Opera</a></li>
          </ul>
          <p>Esta página web utiliza servicios de terceros para recopilar información con fines estadísticos y de uso de la web. Se usan cookies de DoubleClick para mejorar la publicidad que se incluye en el sitio web. Son utilizadas para orientar la publicidad según el contenido que es relevante para un usuario, mejorando así la calidad de experiencia en el uso del mismo.</p>
          <p>En concreto, usamos los servicios de Google Adsense y de Google Analytics para nuestras estadísticas y publicidad. Algunas cookies son esenciales para el funcionamiento del sitio, por ejemplo, el buscador incorporado.</p>
          <p>Nuestro sitio incluye otras funcionalidades proporcionadas por terceros. Usted puede fácilmente compartir el contenido en redes sociales como Facebook, Twitter o Google +, con los botones que hemos incluido a tal efecto.</p>
       </div>

        <div class="w-copy mx-auto mt-8">
          <header class="sticky top-0 bg-white bg-opacity-90">
            <h2 class="text-xl font-medium mb-2 pb-2 border-b border-solid border-gray-200">Advertencia sobre eliminar cookies</h2>
          </header>
          <p>Usted puede eliminar y bloquear todas las cookies de este sitio, pero parte del sitio no funcionará o la calidad de la página web puede verse afectada.</p>
          <p>Si tiene cualquier duda acerca de nuestra política de cookies, puede contactar con esta página web a través de nuestros canales de Contacto o a través del email info@singularbrain.com.</p>
        </div>
      </div>
      <public-footer />

    </div>
  </div>
</template>

<script>
import PublicFooter from '@/components/PublicFooter.vue'
export default {
  name: 'Privacy',
  components: {
    PublicFooter
  },
  data: function () {
    return {}
  }

}
</script>
<style lang="scss" scoped>
.w-copy {
  width: 64ch;
}
p {
  @apply leading-relaxed;
  padding: 0px 0px 1.6em 0px;
}

ul {
  @apply list-disc pl-6 pb-4;
  li {
    padding-bottom: 0.5em;
  }
}
</style>
